import {calculateAge, currencyConverter, formatDateAndTime, imageExists, serverLink} from "../../url";
import React from "react";
import placeholderImage from "../../../images/male_avater.jpg";


export const PatientInfoCard = ({userDetails}) => {
    const imagePath = `${serverLink}public/uploads/passport/${userDetails.passport}`;
    return (
        <div className="col-md-4">
            <div className="card">
                <div className="card-body">
                    <center className="m-t-30"><img src={imageExists(imagePath) ? imagePath : placeholderImage} alt="passport" className="rounded-circle border" width="150" height="150"/>
                        <h4 className="card-title m-t-10">{userDetails.first_name} {userDetails.middle_name} {userDetails.surname}</h4>
                        <h5 className="card-subtitle font-weight-bold text-dark">{userDetails.user_id}</h5>
                        <h4 className="card-subtitle font-weight-bold text-danger">WALLET
                            AMOUNT: {currencyConverter(userDetails.wallet_balance)}</h4>
                        <h5 className="card-subtitle"><span
                            className={userDetails.status === "1" ? "badge badge-success" : "badge badge-danger"}>{userDetails.status === "1" ? <h6 className="font-weight-bold">Active</h6> : <h6 className="font-weight-bold">In Active</h6>}</span></h5>
                    </center>
                </div>
                <div>
                    <hr style={{margin: '0px', padding: '0px'}}/>
                </div>
                <div className="card-body">
                    <small className="text-muted">Gender</small>
                    <h6>{userDetails.gender}</h6>
                    <small className="text-muted">Email address </small>
                    <h6>{userDetails.email_address}</h6>
                    <small className="text-muted p-t-30 db">Phone Number</small>
                    <h6>{userDetails.phone_number}</h6>
                    <small className="text-muted p-t-30 db">NIN Number</small>
                    <h6>{userDetails.nin_number}</h6>
                    <hr/>
                    <h4><b>Validation Details</b></h4>
                    <hr/>
                    <div className="row">
                        <div className="col-md-4">
                            <small className="text-muted p-t-30 db">Email Verification</small>
                            {userDetails.is_email_validated === 1 ? <h6 className="text-success  font-weight-bold">Verified</h6> : <h6 className="text-danger  font-weight-bold">Not Verified</h6>}
                        </div>
                        <div className="col-md-4">
                            <small className="text-muted p-t-30 db">Phone Number Verification</small>
                            {userDetails.is_phone_validated === 1 ? <h6 className="text-success  font-weight-bold">Verified</h6> : <h6 className="text-danger  font-weight-bold">Not Verified</h6>}
                        </div>
                        <div className="col-md-4">
                            <small className="text-muted p-t-30 db">KYC Verification</small>
                            {userDetails.is_KYC === 1 ? <h6 className="text-success  font-weight-bold">Verified</h6> : <h6 className="text-danger  font-weight-bold">Not Verified</h6>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}