import React from "react";
import {Route, Routes} from 'react-router-dom'
import Login from "../auth/login";
import Register from "../auth/register";
import ForgotPassword from "../auth/forgot-password";
import ResetPassword from "../auth/reset-password";

const PubLicRoutes=(props)=>{

    return(
            <Routes>
                <Route  path={"/"} element={<Login/>}  />
                <Route  path={"/login"} element={<Login/>}  />
                <Route  path={"/register"} element={<Register/>}  />
                <Route  path={"/forgot-password"} element={<ForgotPassword/>}  />
                <Route  path={"/reset-password/:slug"} element={<ResetPassword/>}  />
                <Route path="*" element={<Login/>} />
            </Routes>
    )
}
export default PubLicRoutes;