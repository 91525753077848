import React from "react";
import {plans} from "../../url";

export default function PriceControlForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Utility Type</label>
                                <select className="form-control" name="utility" id="utility"
                                        value={props.formData.utility} onChange={props.onEdit}>
                                    <option value="">Select Utility Type</option>
                                    <option value="Data">Data</option>
                                    <option value="Airtime">Airtime</option>
                                    <option value="Electricity">Electricity Bill</option>
                                    <option value="Cable TV">Cable TV Subscription</option>
                                </select>
                            </div>

                            {props.formData.utility === "Airtime" &&
                                <>
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Network Provider</label>
                                        <select className="form-control" name="provider_id" id="provider_id"
                                                value={props.formData.provider_id} onChange={props.onEdit}>
                                            <option value="">Select Network Provider</option>
                                            <option value="1" provider_name="MTN">MTN</option>
                                            <option value="2" provider_name="GLO">GLO</option>
                                            <option value="3" provider_name="9MOBILE">9MOBILE</option>
                                            <option value="4" provider_name="AIRTEL">AIRTEL</option>
                                        </select>
                                    </div>
                                </>
                            }

                            {props.formData.utility === "Data" &&
                                <>
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Network Provider</label>
                                        <select className="form-control" name="provider_id" id="provider_id"
                                                value={props.formData.provider_id} onChange={props.onEdit}>
                                            <option value="">Select Network Provider</option>
                                            <option value="1" provider_name="MTN">MTN</option>
                                            <option value="2" provider_name="GLO">GLO</option>
                                            <option value="3" provider_name="9MOBILE">9MOBILE</option>
                                            <option value="4" provider_name="AIRTEL">AIRTEL</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Plan</label>
                                        <select className="form-control" name="plan" id="plan"
                                                value={props.formData.plan} onChange={props.onEdit}>
                                            <option value="">Select Plan</option>
                                            {
                                                plans.filter(e => e.id === props.formData.provider_id).length > 0 && plans.filter(e => e.id === props.formData.provider_id).map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.planType}>{item.planType}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </>
                            }

                            {props.formData.utility === "Cable TV" &&
                                <>
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Cable TV Provider</label>
                                        <select className="form-control" name="provider_id" id="provider_id"
                                                value={props.formData.provider_id} onChange={props.onEdit}>
                                            <option value="">Select Cable TV Provider</option>
                                            <option value="1" provider_name="GOTV">GOTV</option>
                                            <option value="2" provider_name="DSTV">DSTV</option>
                                            <option value="3" provider_name="STARTIME">STARTIME</option>
                                        </select>
                                    </div>
                                </>
                            }
                            {props.formData.utility === "Electricity" &&
                                <>
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="form-label">Electricity Distribution Company</label>
                                        <select className="form-control" name="provider_id" id="provider_id"
                                                value={props.formData.provider_id} onChange={props.onEdit}>
                                            <option value="">Select Disco</option>
                                            <option value="0" provider_name="All Electricity Distribution Company">All
                                                Electricity Distribution Company
                                            </option>
                                        </select>
                                    </div>
                                </>
                            }

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Action Type</label>
                                <select className="form-control" name="action_type" id="action_type"
                                        value={props.formData.action_type} onChange={props.onEdit}>
                                    <option value="">Select Action Type</option>
                                    <option value="discount">Discount</option>
                                    <option value="surcharge">Surcharge</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label>Amount <span className="text-danger">(Percentage)</span></label>
                                <input
                                    name="amount"
                                    type="number"
                                    className="form-control"
                                    id="amount"
                                    value={props.formData.amount}
                                    onChange={props.onEdit}
                                    placeholder="Amount"
                                    step="0.001"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}