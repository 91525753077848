import React from "react";
import {setDashboardDetails, setLoginDetails, setPermissionDetails} from "../../action/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import {decryptData, encryptData, projectLogo, serverLink} from "../url"
import logo from '../../images/logo.png'
import { useEffect } from "react";

const ResetPassword = (props) => {
    const [loading, setLoading] = useState(false)
    const [loginData, setLoginData] = useState({
        email_address: "",
        password: ""
    })

    const navigate = useNavigate();
    const token = window.location.href.split('/')[4]
    const [reset, setReset] = useState({
        Password: "",
        cPassword: "",
        EmailAddress: ""
    });

    const ValidateToken = async () => {
        await axios
            .get(`${serverLink}admin/forget_password/validate_token/${token}`)
            .then((res) => {
                if (res.data.length === 0) {
                    navigate('/')
                } else {
                    setReset({
                        ...reset,
                        EmailAddress: res.data[0].email_address
                    })
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR", err);
            });
    };

    useEffect(() => {
        ValidateToken();
    }, [])

    const onEdit = (e) => {
        setReset({
            ...reset,
            [e.target.name]: e.target.value,
        });
    };




    const onHandleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.id]: encryptData(e.target.value.trim())
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (loginData.Password === '') {
            toast.error('Please enter your password');
            return;
        }
        if (loginData.cPassword === '') {
            toast.error('Please enter your confirm password');
            return;
        }
        if (loginData.Password !== loginData.cPassword) {
            toast.error('Password does not match');
            return;
        }
        const formData = {
            Password: loginData.Password,
            EmailAddress: reset.EmailAddress
        }
        await axios.patch(`${serverLink}admin/forget_password/change_password/${token}`, formData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success('Your password have been changed successfully.');
                    navigate('/')
                } else {
                    toast.error('Please try again.')
                }
            }).catch((e) => {
                toast.error('Please try again.')
                console.log('NETWORK ERROR')
            })

    }
    return (
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={{background: `url(../../assets/images/big/auth-bg.jpg) no-repeat center center`}}>
            <div className="auth-box">
                <div id="loginform">
                    <div className="d-flex justify-content-center align-items-center">
                        <span className="db"><img src={projectLogo} alt="logo"/></span>

                    </div>

                    <h3 className="mb-3 text-center"><b>Reset Password</b></h3>
                    <div className="row">
                        <div className="col-12">
                            <form className="form-horizontal m-t-20" id="loginform" onSubmit={onSubmit}>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon2"><i
                                            className="ti-pencil"></i></span>
                                    </div>
                                    <input type="password" className="form-control form-control-lg" id="Password"
                                           onChange={onHandleChange} required placeholder="Password"
                                           aria-label="Password"
                                           aria-describedby="basic-addon1"/>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon2"><i
                                            className="ti-pencil"></i></span>
                                    </div>
                                    <input type="password" className="form-control form-control-lg" id="cPassword"
                                           onChange={onHandleChange} required placeholder="Confirm Password"
                                           aria-label="Password"
                                           aria-describedby="basic-addon1"/>
                                </div>
                                <div className="form-group text-center">
                                    <div className="col-xs-12 p-b-20">
                                        <button className="btn btn-block btn-lg btn-info" type="submit">Reset Password
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="custom-control custom-checkbox text-center">
                                        {/*<Link to="/register" id="to-recover" className="text-dark" style={{fontSize: '18px'}}> Create an account</Link>*/}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="recoverform">
                    <div className="logo">
                        <span className="db"><img src="../../assets/images/logo-icon.png" alt="logo"/></span>
                        <h5 className="font-medium m-b-20">Recover Password</h5>
                        <span>Enter your Email and instructions will be sent to you!</span>
                    </div>
                    <div className="row m-t-20">
                        <form className="col-12" action="index.html">
                            <div className="form-group row">
                            <div className="col-12">
                                    <input className="form-control form-control-lg" type="email" required="" placeholder="Username"/>
                                </div>
                            </div>
                            <div className="row m-t-20">
                                <div className="col-12">
                                    <button className="btn btn-block btn-lg btn-danger" type="submit" name="action">Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
        setOnDashboardData: (p) => {
            dispatch(setDashboardDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
