import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import ComponentContainerCard from "../../common/ComponentContainerCard";
import ApexChart3 from "react-apexcharts";
import {Col, Row} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

const UserActivitiesChart = ({user_id, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [reportList, setReportList] = useState([])
    const [paymentMethodChart, setPaymentMethodChart] = useState({});
    const header = ["S/N", "Action", "Country", "City", "Latitude", "Longitude", "Ip Address", "Date"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}admin/user/user-activities/${user_id}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setReportList(result.data);
                    let activity_data = [];
                    [...new Set(result.data.map(obj => obj.action))].forEach((e) => {
                        let count = result.data.filter(item => item.action.trim().toLowerCase() === e.trim().toLowerCase()).length;
                        activity_data.push({ cat: e, count: count });
                    });

                    const trend_by_user_activity = {
                        chart: {
                            height: 580,
                            type: 'bar',
                            toolbar: {
                                show: false,
                            },
                        },
                        title: {
                            text: 'Activities Trend By Users',
                            align: 'left',
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        series: [
                            {
                                name: 'Count',
                                data: activity_data.map(obj => obj.count) ?? [0],
                            },
                        ],
                        colors: ['#2a76f4'],
                        yaxis: {
                            axisBorder: {
                                show: true,
                                color: '#bec7e0',
                            },
                            axisTicks: {
                                show: true,
                                color: '#bec7e0',
                            },
                        },
                        xaxis: {
                            categories: activity_data.map(obj => obj.cat) ?? ['N/A'],
                        },
                        states: {
                            hover: {
                                filter: {
                                    type: 'none',
                                },
                            },
                        },
                        grid: {
                            borderColor: '#f1f3fa',
                        },
                    }
                    setPaymentMethodChart(trend_by_user_activity);
                }else {
                    setReportList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">User Activity</h5>
                    <Row>
                        <Col lg={12}>
                            <ComponentContainerCard>
                                <div className="chart-demo">
                                    {
                                        reportList.length > 0 &&
                                        <ReactApexChart
                                            height={580}
                                            options={paymentMethodChart}
                                            series={paymentMethodChart.series}
                                            type="bar"
                                            className="apex-charts"
                                        />
                                    }

                                </div>
                            </ComponentContainerCard>
                        </Col>
                    </Row>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(UserActivitiesChart);

