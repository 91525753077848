import PageTitle from "../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {encryptData, formatDate, formatDateAndTime, serverLink} from "../url";
import axios from "axios";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import Loader from "../common/loader";
import MenuCard from "../common/card/menu-card";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";
import {PatientInfoCard} from "../patient/utils/patient-info-card";
import UserPayments from "./utils/user-payments";
import UserDataPurchase from "./utils/user-data-purchase";
import UserAirtimePurchase from "./utils/user-airtime-purchase";
import UserElectricityBillPayment from "./utils/user-electricity-bill-payment";
import UserCableTVSubscription from "./utils/user-cable-tv-subscription";
import UserActivities from "./utils/user-activities";
import {showAlert} from "../common/sweetalert/sweetalert";
import ManageUserForm from "./user-form";
import UserActivitiesChart from "./utils/user-activities-chart";

function UserDashboard(props) {
    const token = props.loginData[0]?.token;
    const navigate = useNavigate()
    const {slug} = useParams();
    const [IsFormLoading, setIsFormLoading] = useState(false);
    const [IsLoading, setIsLoading] = useState(false)
    const [userDetails, setUserDetails] = useState({})
    const [rebuildPayment, setRebuildPayment] = useState(null);
    const [formData, setFormData] = useState({
        user_id: "",
        first_name: "",
        middle_name: "",
        surname: "",
        passport: "",
        email_address: "",
        phone_number: "",
        gender: "",
        nin_number: "",
        status: "",
        password: "",
        wallet_balance: "",
        is_email_validated: "",
        is_phone_validated: "",
        is_KYC: "",
        is_multi_auth: "",
        multi_auth: "",
        age: "",
        created_by: `${props.loginData[0]?.user_id}`,
    });

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
        await axios
            .get(`${serverLink}admin/user/details/${atob(slug)}`, token)
            .then(async (res) => {
                if (res.data.length > 0) {
                    setUserDetails(res.data[0])
                    setFormData({
                        ...formData,
                        user_id: res.data[0].user_id,
                        first_name: res.data[0].first_name,
                        middle_name: res.data[0].middle_name,
                        surname: res.data[0].surname,
                        passport: res.data[0].passport,
                        email_address: res.data[0].email_address,
                        phone_number: res.data[0].phone_number,
                        gender: res.data[0].gender,
                        nin_number: res.data[0].nin_number,
                        status: res.data[0].status,
                        password: res.data[0].password,
                        wallet_balance: res.data[0].wallet_balance,
                        is_email_validated: res.data[0].is_email_validated,
                        is_phone_validated: res.data[0].is_phone_validated,
                        is_KYC: res.data[0].is_KYC,
                        is_multi_auth: res.data[0].is_multi_auth,
                        multi_auth: res.data[0].multi_auth,
                        age: res.data[0].age,
                    })
                    setIsLoading(false);
                }else{
                    navigate(`/user/user-manager`)
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    const showStatus = (status) => {
        switch (status) {
            case 'Active':
                return <span className="badge badge-pill badge-primary"><h3>{status}</h3></span>;
            case 'InActive':
                return <span className="badge badge-pill badge-danger"><h3> {status}</h3></span>;
            default:
                return <span className="badge badge-pill badge-secondary"><h3>{status}</h3></span>;
        }
    };

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value,
        });
    }

    const onSubmit = async () => {
        if(formData.first_name === "") {
            showAlert("Empty Field", "Please Enter First Name", "error");
            return false;
        }

        if(formData.surname === "") {
            showAlert("Empty Field", "Please Enter the User Surname", "error");
            return false;
        }

        if(formData.phone_number === "") {
            showAlert("Empty Field", "Please Enter the User Phone Number", "error");
            return false;
        }

        if(formData.email_address === "") {
            showAlert("Empty Field", "Please Enter the User Email Address", "error");
            return false;
        }

        const sendData = {
            ...formData,
            // password: encryptData('123456789'),
        }

        await axios.patch(`${serverLink}admin/user/update`, sendData, token)
            .then(async (result) => {
                if (result.data.message === "success") {
                    toast.success("User Updated Successfully");
                    setIsFormLoading(false);
                    document.getElementById("close-modal").click();
                    await getData();
                } else {
                    await showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                    setIsFormLoading(false);
                }
            })
            .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
                setIsFormLoading(false);
            });
    };


    return (IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "User Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">User Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-payments-tab" data-toggle="pill"
                                           href="#payments"
                                           role="tab" aria-controls="pills-payments" aria-selected="false">Payments</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-data-tab" data-toggle="pill"
                                           href="#data"
                                           role="tab" aria-controls="pills-data" aria-selected="false">Data Purchase</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-airtime-tab" data-toggle="pill"
                                           href="#airtime"
                                           role="tab" aria-controls="pills-airtime" aria-selected="false">Airtime Purchase</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-electricity_bill-tab" data-toggle="pill"
                                           href="#electricity_bill"
                                           role="tab" aria-controls="pills-electricity_bill" aria-selected="false">Electricity Bill Payment</a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-cable-tv-tab" data-toggle="pill"
                                           href="#cable-tv"
                                           role="tab" aria-controls="pills-cable-tv" aria-selected="false">Cable TV Subscription</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-audit-tab" data-toggle="pill"
                                           href="#audit"
                                           role="tab" aria-controls="pills-audit" aria-selected="false">Audit Trail/Log</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-auditChart-tab" data-toggle="pill"
                                           href="#auditChart"
                                           role="tab" aria-controls="pills-auditChart" aria-selected="false">User Activities</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCard userDetails={userDetails}/>
                                                <div className="col-md-8">
                                                    {/*<h4 className="card-title m-t-5">Menu</h4>*/}
                                                    <div className="row">
                                                        <div className="col-md-12 bg-white mb-3">
                                                            <hr style={{margin: '0px', padding: '0px'}}/>
                                                            <div className="card-body">
                                                                <h4><b>Account Details</b></h4>
                                                                <hr/>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <span className="text-muted p-t-30 db">Account Status</span>
                                                                        <h5>{userDetails.status === "1" ? <h6 className="text-success  font-weight-bold">Active</h6> : <h6 className="text-danger  font-weight-bold">In Active</h6>}</h5>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <span className="text-muted p-t-30 db">KYC Status</span>
                                                                        <h5>{userDetails.KYC === 1 ? <h6 className="text-success  font-weight-bold">Verified</h6> : <h6 className="text-danger  font-weight-bold">Not Verified</h6>}</h5>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <span className="text-muted p-t-30 db">Date Created</span>
                                                                        <h5>{formatDateAndTime(userDetails.created_date, 'date')}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <MenuCard title="Edit Profile" icon="ti-pencil"
                                                                  size="col-md-12" color="info"
                                                                  modal={"edit-modal"}
                                                                  onClick={() => {}}/>

                                                        <div className="col-md-12 mt-5">
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <h3><b>Account
                                                                        Status:</b> {showStatus(userDetails.status === "1" ? 'Active' : 'InActive')}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="pills-payments-tab">
                                        <div className="card-body">
                                            <UserPayments user_id={userDetails.user_id} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="data" role="tabpanel" aria-labelledby="pills-data-tab">
                                        <div className="card-body">
                                            <UserDataPurchase user_id={userDetails.user_id} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="airtime" role="tabpanel" aria-labelledby="pills-airtime-tab">
                                        <div className="card-body">
                                            <UserAirtimePurchase user_id={userDetails.user_id} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="electricity_bill" role="tabpanel" aria-labelledby="pills-electricity_bill-tab">
                                        <div className="card-body">
                                            <UserElectricityBillPayment user_id={userDetails.user_id} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="cable-tv" role="tabpanel" aria-labelledby="pills-cable-tv-tab">
                                        <div className="card-body">
                                            <UserCableTVSubscription user_id={userDetails.user_id} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="audit" role="tabpanel" aria-labelledby="pills-audit-tab">
                                        <div className="card-body">
                                            <UserActivities user_id={userDetails.user_id} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="auditChart" role="tabpanel" aria-labelledby="pills-auditChart-tab">
                                        <div className="card-body">
                                            <UserActivitiesChart user_id={userDetails.user_id} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageUserForm
                    id="edit-modal"
                    close="close-modal"
                    title="Manage User"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        userDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(UserDashboard);
