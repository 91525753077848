import PageTitle from "../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import { formatDateAndTime, serverLink} from "../url";
import {showAlert, showConfirm} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../common/data-table/data-table";
import Loader from "../common/loader";
import ManageSystemAdminForm from "./manage-system-admin-form";

function SystemAdmin(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [reportList, setReportList] = useState([])
    const header = [
        "S/N",
        "User ID",
        "Name",
        "Gender",
        "Email Address",
        "Status",
        "Added By",
        "Added On",
        "Update",
        "Delete"
    ];

    const [formData, setFormData] = useState({
        user_id: "",
        first_name: "",
        middle_name: "",
        surname: "",
        email_address: "",
        gender: "",
        status: "",
        password: "",
        created_by: `${props.loginData[0]?.user_id}`,
    });

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}admin/users/list`, token).then((result) => {
            if (result.data.length > 0) {
                setReportList(result.data);
            }else{
                setReportList([])
            }
            setIsLoading(false)
        })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}admin/users/delete/${id}`, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.user_id}</td>
                        <td>{item.first_name} {item.middle_name} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.email_address}</td>
                        <td className={item.status === 1 ? 'text-success' : 'text-danger'}>{item.status === 1 ? 'Active' : 'InActive'}</td>
                        <td>{item.created_by}</td>
                        <td>{formatDateAndTime(item.created_date, 'date')}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal"
                               data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       user_id: item.user_id,
                                       first_name: item.first_name,
                                       middle_name: item.middle_name,
                                       surname: item.surname,
                                       passport: item.passport,
                                       email_address: item.email_address,
                                       gender: item.gender,
                                       status: item.status,
                                       password: item.password,
                                   })
                               }}>
                                Edit
                            </a></td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-danger btn-sm "} onClick={() => delete_item(item.user_id)}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };


    const onSubmit = async () => {
        if(formData.first_name === "") {
            showAlert("Empty Field", "Please Enter First Name", "error");
            return false;
        }

        if(formData.surname === "") {
            showAlert("Empty Field", "Please Enter the User Surname", "error");
            return false;
        }

        if(formData.phone_number === "") {
            showAlert("Empty Field", "Please Enter the User Phone Number", "error");
            return false;
        }

        if(formData.email_address === "") {
            showAlert("Empty Field", "Please Enter the User Email Address", "error");
            return false;
        }


        if (formData.user_id === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}admin/users/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("User Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            user_id: "",
                            first_name: "",
                            middle_name: "",
                            surname: "",
                            email_address: "",
                            gender: "",
                            status: "",
                            password: "",
                        });
                    }else if (result.data.message === "exist"){
                        setIsFormLoading(false);
                        showAlert(
                            "Duplicate Entry",
                            "The user already been added",
                            "error"
                        );
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}admin/users/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("User Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            user_id: "",
                            first_name: "",
                            middle_name: "",
                            surname: "",
                            email_address: "",
                            gender: "",
                            status: "",
                            password: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }


    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "System Admin User Management"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage System Admin</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group mr-3">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        user_id: "",
                                                        first_name: "",
                                                        middle_name: "",
                                                        surname: "",
                                                        email_address: "",
                                                        gender: "",
                                                        status: "",
                                                        password: "",
                                                    });
                                                }}>
                                                    <i className="fa fa-plus"></i> Add System Admin
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="report" header={header} body={showTable()} title="System Admin Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageSystemAdminForm
                    title="Manage System Administrator"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(SystemAdmin);

