import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";
function WalletTopUp(props) {
    const { user_id, user_name, walletAmount} = props;
    const navigate = useNavigate()
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const Initial = {
        user_id: user_id,
        user_name: user_name,
        payment_reference: '',
        payment_method: '',
        payment_amount: 0,
        payment_status: "Paid",
        payment_for: "Wallet TopUp",
        payment_type: "Credit",
        amount_due: 0,
        amount_paid: 0,
        item_code: "Admin",
        item_type: "Wallet TopUp",
        created_name: `${props.loginData[0]?.first_name} ${props.loginData[0]?.middle_name} ${props.loginData[0]?.surname}`,
        created_by: `${props.loginData[0]?.user_id}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const creditWallet = async () => {
        if (formData.payment_method === ""){
            toast.error('Please select payment method')
            return false
        }

        if (formData.amount_paid.toString().trim() === '0'){
            toast.error('Please enter amount to credit')
            return false
        }


        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            user_id: user_id,
            user_name: user_name,
            amount_paid: formData.amount_paid,
            amount_due: formData.amount_paid,
            payment_reference: "WalletFunding"+generate_token(10),
            item_name: `${formData.amount_paid} Wallet TopUp`,
        }
        await axios.post(`${serverLink}payment/post-payment`, sendData, token).then(async (res) => {
            if (res.data.message === "success") {
                toast.success("Wallet Credited Successfully");
                setIsFormLoading(false);
                document.getElementById("closewallet").click();
                resetFormData()
                setTimeout(()=>{
                    window.location.reload();
                }, 2000)
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong posting payment. Please try again!");
            }
        }).catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`} id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{user_name}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{user_id}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Payment Method</label>
                                <select name="payment_method" className="form-control" id="payment_method"
                                        onChange={onEdit}
                                        value={formData.payment_method}>
                                    <option value="">Select Payment Method</option>
                                    <option value="POS">POS</option>
                                    <option value="Transfer">Transfer</option>
                                    <option value="Cash Deposit">Cash Deposit</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Amount Paid</label>
                                <input
                                    type="number"
                                    id="amount_paid"
                                    name="amount_paid"
                                    className="form-control"
                                    value={formData.amount_paid}
                                    step="0.01"
                                    min={0}
                                    onChange={onEdit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="alert alert-warning">Note: Once submitted, the transaction cannot be reverted!
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={() =>
                                    showConfirm(
                                        "Warning",
                                        `Are you sure you want to credit ${user_name} - (${user_id}) wallet with ${currencyConverter(formData.amount_paid)}?`,
                                        "warning"
                                    ).then(async (confirm) => {
                                        if (confirm) {
                                            await creditWallet();
                                        }
                                    })
                                } className="btn btn-danger waves-effect waves-light">{"submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(WalletTopUp);