import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import PaymentDataTable from "../../report/data-table/payment-data-table";

const UserPayments = ({user_id, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [paymentList, setPaymentList] = useState([])
    const header = ["S/N", "Payment Purpose", "Payment Method", "Amount Paid", "Payment Type", "Status", "Payment Date", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}admin/user/payments/${user_id}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPaymentList(result.data);
                }else {
                    setPaymentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return paymentList.length > 0 &&  paymentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.payment_method}</td>
                        <td>{currencyConverter(item.amount_paid)}</td>
                        <td className={item.payment_type === 'Credit' ? 'text-success' : 'text-danger'}>{item.payment_type}</td>
                        <td className={item.payment_status === 'Paid' ? 'text-success' : 'text-danger'}>{item.payment_status}</td>
                        <td>{formatDateAndTime(item.payment_date, 'date')}</td>
                        <td>Details</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const  getTotal = () => {
        let total_amount = 0;
        paymentList.map((item, index) => {
            total_amount += item.amount_paid;
        });
        return total_amount;
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">User Payments</h5>
                    <div className="user-payments">
                        {
                            paymentList.length > 0 ? <div className="text-right"><h4><b>{`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}</b></h4></div> : <></>
                        }
                        <PaymentDataTable
                            tableID="user-payments"
                            header={header}
                            date={"("+formatDateAndTime(Date.now(), 'month_and_year')+")"}
                            caption={`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}
                            body={showTable()}
                            title={`User Payments Report`} />
                    </div>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(UserPayments);

