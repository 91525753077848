import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useState} from "react";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {FaNairaSign} from "react-icons/fa6";
import MenuIcon from "../utils/menu-icon";
import {FaUsersCog} from "react-icons/fa";

function AnalyticsReportDashboard(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)


    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Analytics Report Dashboard"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <MenuIcon title="Users and App Activities Report" icon={<FaUsersCog size={30}/>} size="col-md-6"
                                                   color="#424f57"
                                                   url={`/report/analytics/user-activities`} />
                                        <MenuIcon title="Payments Analytic Report" icon={<FaNairaSign size={30}/>} size="col-md-6"
                                                   color="#424f57"
                                                   url={`/report/analytics/payment`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(AnalyticsReportDashboard);

