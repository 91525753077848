import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import PaymentDataTable from "../../report/data-table/payment-data-table";

const UserElectricityBillPayment = ({user_id, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [reportList, setReportList] = useState([])
    const header = ["S/N", "Provider Name", "Package Name", "Amount", "Meter Number", "Customer Name", "Customer No", "Token", "Status", "Date"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}admin/user/electricity-bill-payment/${user_id}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setReportList(result.data);
                }else {
                    setReportList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return reportList.length > 0 &&  reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.provider_name}</td>
                        <td>{item.package_name}</td>
                        <td>{currencyConverter(item.amount)}</td>
                        <td>{item.meter_number}</td>
                        <td>{item.customer_name}</td>
                        <td>{item.customer_no}</td>
                        <td>{item.token}</td>
                        <td className={item.status === 'Successful' ? 'text-success' : 'text-danger'}>{item.status}</td>
                        <td>{formatDateAndTime(item.created_at, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    const  getTotal = () => {
        let total_amount = 0;
        reportList.map((item, index) => {
            total_amount += item.amount;
        });
        return total_amount;
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Electricity Bill Payment</h5>
                    <div className="table-responsive">
                        {
                            reportList.length > 0 ? <div className="text-right"><h4><b>{`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}</b></h4></div> : <></>
                        }
                        <PaymentDataTable
                            tableID="user-electricity"
                            header={header}
                            date={"("+formatDateAndTime(Date.now(), 'month_and_year')+")"}
                            caption={`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}
                            body={showTable()}
                            title={`User Electricity Bill Payment`} />
                    </div>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(UserElectricityBillPayment);

