import PageTitle from "../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {encryptData, formatDate, serverLink} from "../url";
import { showAlert, showConfirm } from "../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import DataTable from "../common/data-table/data-table";
import Loader from "../common/loader";
import {Link} from "react-router-dom";
import { setGeneralDetails} from "../../action/actions";

function ManageUsers(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [IsFormLoading, setIsFormLoading] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [patientList, setUserList] = useState([]);

    const [formData, setFormData] = useState({
        patientID: "",
        title: "",
        firstName: "",
        middleName: "",
        surname: "",
        passport: "",
        ImagePath: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "",
        dateOfBirth: "",
        bloodGroup: "",
        maritalStatus: "",
        nextOfKinName: "",
        nextOfKinPhone: "",
        nextOfKinRelationship: "",
        residentialAddress: "",
        nationality: "",
        state: "",
        lga: "",
        patientSerial: "",
        occupation: "",
        altPhoneNumber: "",
        password: "",
        ward: "",
        zipCode: "",
        officeAddress: "",
        religion: "",
        submittedBy: `${props.loginData[0]?.user_id}`,
    });

    const header = [
        "S/N",
        "User ID",
        "Name",
        "Gender",
        "Phone Number",
        "Email Address",
        "Email Verified",
        "KYC Verified",
        "Action"
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, [""]);

    const getData = async () => {
        await axios
            .get(`${serverLink}admin/user/list/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setUserList(result.data);
                } else {
                    setUserList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios
            .delete(`${serverLink}user/delete/${id}`, token)
            .then((res) => {
                if (res.data.message === "success") {
                    toast.success("Deleted Successfully");
                    getData();
                } else {
                    toast.error("NETWORK ERROR. Please try again!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("NETWORK ERROR. Please try again!");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.user_id}</td>
                        <td>{item.first_name} {item.middle_name} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phone_number}</td>
                        <td>{item.email_address}</td>
                        <td>{item.is_email_validated === 1 ? 'Yes' : 'No'}</td>
                        <td>{item.is_KYC === 1 ? 'Yes' : 'No'}</td>
                        <td className="d-flex">
                            <Link to={`/user/user-dashboard/${btoa(item.user_id)}`}
                                  className={"btn btn-info btn-sm  mr-3"}

                            >
                                Dashboard
                            </Link>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const resetItem = () => {
        setFormData({
            patientID: "",
            title: "",
            firstName: "",
            middleName: "",
            surname: "",
            passport: "",
            ImagePath: "",
            emailAddress: "",
            phoneNumber: "",
            gender: "",
            dateOfBirth: "",
            bloodGroup: "",
            maritalStatus: "",
            nextOfKinName: "",
            nextOfKinPhone: "",
            nextOfKinRelationship: "",
            residentialAddress: "",
            nationality: "",
            state: "",
            lga: "",
            patientSerial: "",
            occupation: "",
            altPhoneNumber: "",
            password: "",
            ward: "",
            zipCode: "",
            officeAddress: "",
            religion: "",
            submittedBy: `${props.loginData[0]?.user_id}`,
        });
    };

    const onSubmit = async () => {
        if(formData.firstName === "") {
            showAlert("Empty Field", "Please Select the Patient First Name", "error");
            return false;
        }

        if(formData.surname === "") {
            showAlert("Empty Field", "Please Enter the Patient Surname", "error");
            return false;
        }

        if(formData.phoneNumber === "") {
            showAlert("Empty Field", "Please Enter the Patient Phone Number", "error");
            return false;
        }

        if(formData.gender === "") {
            showAlert("Empty Field", "Please Select the Patient Gender", "error");
            return false;
        }

        if(formData.bloodGroup === "") {
            showAlert("Empty Field", "Please Select the Patient Blood Group", "error");
            return false;
        }



        if(formData.dateOfBirth === "" ||  formData.dateOfBirth === null) {
            showAlert("Empty Field", "Please Select the Patient Date of Birth", "error");
            return false;
        }


        if(formData.maritalStatus === "") {
            showAlert("Empty Field", "Please Select the Patient Marital Status", "error");
            return false;
        }

        if(formData.nextOfKinName === "") {
            showAlert("Empty Field", "Please Enter the Patient Next of Kin Name", "error");
            return false;
        }

        if(formData.nextOfKinPhone === "") {
            showAlert("Empty Field", "Please Enter the Patient Next of Kin Phone Number", "error");
            return false;
        }

        if(formData.nextOfKinRelationship === "") {
            showAlert("Empty Field", "Please Select the Patient Next of Kin Relationship", "error");
            return false;
        }


        if(formData.nationality === "") {
            showAlert("Empty Field", "Please Select Nationality", "error");
            return false;
        }

        if(formData.state === "") {
            showAlert("Empty Field", "Please Select the Patient State", "error");
            return false;
        }

        if(formData.lga === "") {
            showAlert("Empty Field", "Please Select the Patient LGA", "error");
            return false;
        }

        if(formData.religion === "") {
            showAlert("Empty Field", "Please Select the Patient Religion", "error");
            return false;
        }


        if(formData.residentialAddress === "") {
            showAlert("Empty Field", "Please Select the Patient Residential Address", "error");
            return false;
        }

        const sendData = {
            ...formData,
            password: encryptData('123456789'),
            dateOfBirth: formatDate(formData.dateOfBirth),
        }

        if (formData.patientID === "") {
            axios
                .post(`${serverLink}patient/add`, sendData, token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', res.data.patientId)
                            axios.patch(`${serverLink}patient/uploadPatientPhoto`, formData2)
                                .then(async (result) => {
                                    toast.success("Patient Added Successfully");
                                    setIsFormLoading(false);
                                    await getData();
                                    document.getElementById("close").click();
                                    resetItem();
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("Patient Added Successfully");
                            setIsFormLoading(false);
                            await getData();
                            document.getElementById("close").click();
                            resetItem();
                        }
                    }
                    if (res.data.message === "exist") {
                        await showAlert(
                            "Error",
                            `Patient with given details already exist!`,
                            "error"
                        );
                        setIsSubmittingForm(false);
                    }
                })
                .catch((error) => {
                    console.log("Error", error);
                });
        }else {
            await axios
                .patch(`${serverLink}patient/update`, sendData, token)
                .then(async (result) => {
                    if (result.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', formData.patientID)
                            axios.patch(`${serverLink}patient/uploadPatientPhoto`, formData2)
                                .then(async (result) => {
                                    toast.success("Patient Updated Successfully");
                                    setIsFormLoading(false);
                                    await getData();
                                    setIsSubmittingForm(false);
                                    document.getElementById("close").click();
                                    resetItem();
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("Patient Updated Successfully");
                            setIsFormLoading(false);
                            await getData();
                            setIsSubmittingForm(false);
                            document.getElementById("close").click();
                            resetItem();
                        }
                    } else {
                        await showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                        setIsSubmittingForm(false);
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                    setIsSubmittingForm(false);
                });
        }
    };

    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };


    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "User Management"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Manage Users</h4>
                                <div className="table-responsive">
                                    <DataTable
                                        tableID="Users"
                                        header={header}
                                        body={showTable()}
                                        title="Users Report"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<ManagePatientForm*/}
            {/*    title="Manage Users"*/}
            {/*    formData={formData}*/}
            {/*    onEdit={onEdit}*/}
            {/*    onSubmit={onSubmit}*/}
            {/*    onImageChange={onImageChange}*/}
            {/*    countryData={CountryData}*/}
            {/*    stateList={stateList}*/}
            {/*    lgaList={lgaList}*/}
            {/*    IsFormLoading={IsFormLoading}*/}
            {/*/>*/}
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
