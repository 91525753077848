import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import { connect } from "react-redux";
import Loader from "../../common/loader";
import { setGeneralDetails} from "../../../action/actions";
import PaymentDataTable from "../data-table/payment-data-table";

function ElectricityBillPayment(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [reportList, setReportList] = useState([]);
    const header = ["S/N", "User Name", "UserID", "Provider Name", "Package Name", "Amount", "Meter Number", "Customer Name", "Customer No", "Token", "Status", "Date"];

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}report/payment/electricity-bill-payment`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setReportList(result.data);
                }else {
                    setReportList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return reportList.length > 0 &&  reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.userName}</td>
                        <td>{item.user_id}</td>
                        <td>{item.provider_name}</td>
                        <td>{item.package_name}</td>
                        <td>{currencyConverter(item.amount)}</td>
                        <td>{item.meter_number}</td>
                        <td>{item.customer_name}</td>
                        <td>{item.customer_no}</td>
                        <td>{item.token}</td>
                        <td className={item.status === 'Successful' ? 'text-success' : 'text-danger'}>{item.status}</td>
                        <td>{formatDateAndTime(item.created_at, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const  getTotal = () => {
        let total_amount = 0;
        reportList.map((item, index) => {
            total_amount += item.amount;
        });
        return total_amount;
    }

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Electricity Bill Payment Report"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Electricity Bill Payment Report</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">
                                        <div className="btn-group">

                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {
                                        reportList.length > 0 ? <div className="text-right"><h4><b>{`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}</b></h4></div> : <></>
                                    }
                                    <PaymentDataTable
                                        tableID="user-Electricity-Bill-Payment-Report"
                                        header={header}
                                        date={"("+formatDateAndTime(Date.now(), 'month_and_year')+")"}
                                        caption={`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}
                                        body={showTable()}
                                        title={`Electricity Bill Payment Report`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ElectricityBillPayment);
