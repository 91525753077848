import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import PriceControlForm from "./price-control-form";

function PriceControl(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [reportList, setReportList] = useState([])
    const header = ["S/N", "Utility", "Provider", "Plan", "Action Type", "Amount (Percentage)", "Updated By", "Updated Date",  "Edit", "Delete"];
    const [formData, setFormData] = useState({
        price_id: "",
        provider_id: "",
        utility: "",
        provider_name: "",
        plan: "",
        action_type: "",
        amount: "",
        created_by: `${props.loginData[0]?.user_id}`,
    })

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}admin/app-manager/price-control/list`, token).then((result) => {
            if (result.data.length > 0) {
                setReportList(result.data);
            }else{
                setReportList([])
            }
            setIsLoading(false)
        })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}admin/app-manager/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.utility}</td>
                        <td>{item.provider_name}</td>
                        <td>{item.plan ? item.plan : '--'}</td>
                        <td className={`text-capitalize ${item.action_type === 'discount' ? 'text-success' : 'text-danger'}`}>{item.action_type}</td>
                        <td>{item.amount}</td>
                        <td>{item.created_by}</td>
                        <td>{formatDateAndTime(item.created_date,'date')}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal"
                               data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       price_id: item.price_id,
                                       provider_id: item.provider_id,
                                       utility: item.utility,
                                       provider_name: item.provider_name,
                                       plan: item.plan,
                                       action_type: item.action_type,
                                       amount: item.amount,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-danger btn-sm "} onClick={() => delete_item(item.price_id)}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        if (e.target.id === 'provider_id') {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const providerName = selectedOption.getAttribute('provider_name');

            setFormData({
                ...formData,
                provider_name: providerName,
                [e.target.id]: e.target.value,
            });
        } else {
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });
        }
    };


    const onSubmit = async () => {
        if (formData.utility.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select utility type", "error");
            return false;
        }
        if (formData.provider_name.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select provider name", "error");
            return false;
        }
        if (formData.utility === 'Data' && formData.plan.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select plan", "error");
            return false;
        }
        if (formData.action_type.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select action type", "error");
            return false;
        }
        if (formData.amount.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter amount", "error");
            return false;
        }

        if (formData.price_id === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}admin/app-manager/price-control/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Item Price Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            price_id: "",
                            provider_id: "",
                            utility: "",
                            provider_name: "",
                            plan: "",
                            action_type: "",
                            amount: "",
                        });
                    }else if (result.data.message === "duplicate"){
                        setIsFormLoading(false);
                        showAlert(
                            "Duplicate Entry",
                            "The item price has already been added",
                            "error"
                        );
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}admin/app-manager/price-control/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Item Price Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            price_id: "",
                            provider_id: "",
                            utility: "",
                            provider_name: "",
                            plan: "",
                            action_type: "",
                            amount: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }


    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Price Control"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage Price Control</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group mr-3">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        price_id: "",
                                                        provider_id: "",
                                                        utility: "",
                                                        provider_name: "",
                                                        plan: "",
                                                        action_type: "",
                                                        amount: "",
                                                    });
                                                }}>
                                                    <i className="fa fa-plus"></i> Add Item Price
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="item-price" header={header} body={showTable()} title="Price Control Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PriceControlForm
                    title="Manage Price Control"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PriceControl);
