import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {currencyConverter, extractAndSumNumbers, formatDateAndTime, serverLink} from "../../url";
import { connect } from "react-redux";
import Loader from "../../common/loader";
import { setGeneralDetails } from "../../../action/actions";
import PaymentDataTable from "../data-table/payment-data-table";

function DataPurchaseDaily(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [showReport, setShowReport] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [formData, setFormData] = useState({ month_id: "" })
    const header = ["S/N", "User Name", "UserID", "Data Plan", "Data Plan Type", "Network Provider", "Amount", "Duration", "Beneficiary", "Status", "Date"];

    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
        getData(val)
    }

    useEffect(() => {
        setTimeout(()=>{
            setIsLoading(false)
        }, 2000)
    }, []);

    const getData = async (month) => {
        if (month !== ""){
            setShowReport(false)
            setIsLoading(true)
            await axios
                .get(`${serverLink}report/payment/data-daily-purchase?payment_date=${month}`, token)
                .then((result) => {
                    if (result.data.length > 0) {
                        setReportList(result.data);
                    } else {
                        setReportList([]);
                    }
                    setIsLoading(false);
                    setShowReport(true);
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }
    };

    const  getTotal = () => {
        let total_amount = 0;
        reportList.map((item, index) => {
            total_amount += item.amount;
        });
        return total_amount;
    }

    const  getTotalData = () => {
        let total_amount = [];
        reportList.map((item, index) => {
            total_amount.push(item.data_plan) ;
        });
        return total_amount;
    }

    const  showTable = () => {
        try {
            return reportList.length > 0 &&  reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.userName}</td>
                        <td>{item.user_id}</td>
                        <td>{item.data_plan}</td>
                        <td>{item.data_plan_type}</td>
                        <td>{item.provider_name}</td>
                        <td>{currencyConverter(item.amount)}</td>
                        <td>{item.duration}</td>
                        <td>{item.beneficiary_number}</td>
                        <td className={item.status === 'Successful' ? 'text-success' : 'text-danger'}>{item.status}</td>
                        <td>{formatDateAndTime(item.created_at, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Daily Data Purchase Report"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="form-group">
                                        <label htmlFor="month_id">Select Date </label>
                                        <input type="date" id="month_id"
                                               className="form-control"
                                               value={formData.month_id}
                                               onChange={onChange}
                                               max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                               required />
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        {
                                            showReport ? <div className="text-right"><h4> </h4></div> : <></>
                                        }
                                        {
                                            showReport ? <div className="text-right"><h4> </h4></div> : <></>
                                        }
                                        {
                                            showReport ? <div className="text-right"><h4> </h4></div> : <></>
                                        }
                                        {
                                            showReport ? <div className="text-right"><h4><b>{`TOTAL DATA: ${extractAndSumNumbers(getTotalData() ?? [])}`}</b></h4></div> : <></>
                                        }
                                        {
                                            showReport ? <div className="text-right"><h4><b>{`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}</b></h4></div> : <></>
                                        }
                                    </div>
                                    {
                                        showReport ?
                                            <PaymentDataTable
                                                tableID="daily-payment"
                                                header={header}
                                                date={"("+formatDateAndTime(formData.month_id, 'date_and_time')+")"}
                                                caption={`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}
                                                caption2={`TOTAL DATA: ${extractAndSumNumbers(getTotalData() ?? [])}`}
                                                body={showTable()}
                                                title={`Data Purchase Daily For ${formatDateAndTime(formData.month_id, 'date_and_time')}`} />
                                            : <></>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DataPurchaseDaily);
