import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {setGeneralDetails} from "../../../action/actions";
import WalletTopUp from "./wallet-topup-form";
import {FaNairaSign} from "react-icons/fa6";
import {FaRegCreditCard, FaResolving} from "react-icons/fa";
import DebitForm from "./debit-form";

function FundUserWallet(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [reportList, setReportList] = useState([]);
    const [formData, setFormData] = useState({
        user_id: "",
        first_name: "",
        middle_name: "",
        surname: "",
        email_address: "",
        phone_number: "",
        gender: "",
        password: "",
        status: "",
        submittedBy: `${props.loginData[0]?.user_id}`,
    });

    const header = [
        "S/N",
        "User ID",
        "Name",
        "Gender",
        "Phone Number",
        "Email Address",
        "status",
        "Credit Wallet",
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        await axios
            .get(`${serverLink}admin/user/list/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setReportList(result.data);
                } else {
                    setReportList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const showTable = () => {
        try {
            return reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.user_id}</td>
                        <td>{item.first_name} {item.middle_name} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phone_number}</td>
                        <td>{item.email_address}</td>
                        <td className={item.status === "1" ? 'text-success' : 'text-danger'}>{item.status === "1" ? 'Active' : 'In Active'}</td>
                        <td className="d-flex justify-content-center">
                            <a href="#"
                               className={"btn btn-primary btn-sm"}
                               data-toggle="modal"
                               data-target="#wallet-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       user_id: item.user_id,
                                       first_name: item.first_name,
                                       middle_name: item.middle_name,
                                       surname: item.surname,
                                       email_address: item.email_address,
                                       phone_number: item.phone_number,
                                       gender: item.gender,
                                       wallet_balance: item.wallet_balance,
                                       status: item.status,
                                   });
                               }}
                            >
                                <FaNairaSign size={25} />
                            </a>
                            <a href="#"
                               className={"btn btn-primary btn-sm ml-2"}
                               data-toggle="modal"
                               data-target="#debit-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       user_id: item.user_id,
                                       first_name: item.first_name,
                                       middle_name: item.middle_name,
                                       surname: item.surname,
                                       email_address: item.email_address,
                                       phone_number: item.phone_number,
                                       gender: item.gender,
                                       wallet_balance: item.wallet_balance,
                                       status: item.status,
                                   });
                               }}
                            >
                                <FaRegCreditCard size={25} />
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    return IsLoading ? (
        <Loader />
    ) : (
        <>
        <PageTitle title={["Home", "Wallet TopUp"]} />
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">User's List</h4>
                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                <div className="ml-auto">
                                </div>
                            </div>
                            <DataTable
                                tableID="Users"
                                header={header}
                                body={showTable()}
                                title="Users Report"
                    />
                </div>
            </div>
        </div>
        </div>
        </div>

            <WalletTopUp
                id="wallet-modal"
                title="Wallet TopUp"
                close="closewallet"
                user_id={formData.user_id}
                user_name={formData.first_name + ' ' + formData.middle_name + ' ' + formData.surname}
                walletAmount={formData.wallet_balance}
            />

            <DebitForm
                id="debit-modal"
                title="Excess Fund Debit"
                close="closewallet"
                user_id={formData.user_id}
                user_name={formData.first_name + ' ' + formData.middle_name + ' ' + formData.surname}
                walletAmount={formData.wallet_balance}
            />

</>
);
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FundUserWallet);
