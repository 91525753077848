import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";

const UserActivities = ({user_id, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [reportList, setReportList] = useState([])
    const header = ["S/N", "Action", "Country", "City", "Latitude", "Longitude", "Ip Address", "Date"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}admin/user/user-activities/${user_id}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setReportList(result.data);
                }else {
                    setReportList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return reportList.length > 0 &&  reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.action}</td>
                        <td>{item.country}</td>
                        <td>{item.city}</td>
                        <td>{item.lat}</td>
                        <td>{item.lon}</td>
                        <td>{item.ip}</td>
                        <td>{formatDateAndTime(item.inserted_date, 'date_and_time')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const  getTotal = () => {
        let total_amount = 0;
        reportList.map((item, index) => {
            total_amount += item.amount;
        });
        return total_amount;
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Audit Trail/Log</h5>
                    <DataTable tableID="user-audit" header={header} body={showTable()} title="User Audit Trail/Log"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(UserActivities);

