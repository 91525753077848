import ComponentContainerCard from '../../common/ComponentContainerCard';
import PageTitle from "../../common/pagetitle/pagetitle";
import {
    Card,
    CardBody,
    Col,
    Row,
} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {
    currencyConverter, formatDate,
    serverLink
} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import ReactApexChart from "react-apexcharts";
import StatisticWidget from "../utils/StatisticWidget";

const PaymentAnalyticsDashboard = ({loginData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [showReport, setShowReport] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [reportBackupList, setReportBackupList] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [serviceTypePayment, setServiceTypePayment] = useState({});
    const [paymentMethodChart, setPaymentMethodChart] = useState({});
    const [formData, setFormData] = useState({ payment_method: '', payment_type: '', payment_date: "" })

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        setShowReport(false)
        setIsLoading(true)
        await axios.get(`${serverLink}report/payment/finance/all-payment`, token).then((result) => {
            if (result.data.message === 'success') {
                console.log(result.data)
                setReportList(result.data.data);
                setReportBackupList(result.data.data);
                setPaymentMethod( [...new Set(result.data.data.map(obj => obj.payment_method))])
                setPaymentTypes( [...new Set(result.data.data.map(obj => obj.payment_type))])
                // setDistinctPaymentFor( [...new Set(result.data.data.map(obj => obj.payment_for))])
            } else {
                setReportList([]);
                setReportBackupList([]);
                toast.error(result.data.message)
            }
            setIsLoading(false);
            generate_analytics();
        })
            .catch((err) => {
                toast.error('NETWORK ERROR')
            });
    };

    const generate_analytics = () => {
        let service_type_data = [], payment_method_data = [];
        let distinct_payment_for = [...new Set(reportList.map(obj => obj.payment_for))];
        let distinct_payment_method = [...new Set(reportList.map(obj => obj.payment_method))];

        const distinctPaymentForList = distinct_payment_for || [];
        const distinctPaymentMethod = distinct_payment_method || [];
        const reportListData = reportList || [];

        distinctPaymentForList.forEach((e) => {
            let amount = reportListData.filter(item => item.payment_for === e).reduce((acc, service) => acc + (service.amount_paid || 0), 0);
            service_type_data.push({ cat: e, amount: amount });
        });

        distinctPaymentMethod.forEach((e) => {
            let amount = reportListData.filter(item => item.payment_method === e).reduce((acc, service) => acc + (service.amount_paid || 0), 0);
            payment_method_data.push({ cat: e, amount: amount });
        });


        const service_type_payment = {
            series: [
                {
                    name: 'Amount Paid',
                    data: service_type_data.map(obj => obj.amount) ?? [0],
                }
            ],
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false,
                },
            },
            colors: ['#008ffb'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: [3],
            },
            title: {
                text: 'Payment Trends by Service Types',
                align: 'left',
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5,
                },
            },
            xaxis: {
                categories: service_type_data.map(obj => obj.cat) ?? ['N/A'],
            },
        };

        const trend_by_payment_method = {
            chart: {
                height: 380,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            title: {
                text: 'Payment Trend by Payment Method ',
                align: 'left',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            series: [
                {
                    name: 'Amount Paid',
                    data: payment_method_data.map(obj => obj.amount) ?? [0],
                },
            ],
            colors: ['#2a76f4'],
            yaxis: {
                axisBorder: {
                    show: true,
                    color: '#bec7e0',
                },
                axisTicks: {
                    show: true,
                    color: '#bec7e0',
                },
            },
            xaxis: {
                categories: payment_method_data.map(obj => obj.cat) ?? ['N/A'],
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                    },
                },
            },
            grid: {
                borderColor: '#f1f3fa',
            },
        }

        setServiceTypePayment(service_type_payment);
        setPaymentMethodChart(trend_by_payment_method);
        setIsLoading(false);
        setShowReport(true);
    };



    useEffect(() => {
        onFilter(formData.payment_method, formData.payment_type, formData.payment_date);
    }, [formData.payment_method, formData.payment_type, formData.payment_date]);

    useEffect(() => {
        if (reportList.length > 0) {
            setTimeout(() => {
                generate_analytics();
            }, 1000);
        }else{
            setIsLoading(false);
        }
    }, [reportList]);

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onFilter = (payment_method, payment_type, payment_date) => {
        setIsLoading(true);
        setShowReport(false);
        const filtered = reportBackupList.filter(x =>
            x.payment_method.includes(payment_method) &&
            x.payment_type.includes(payment_type) &&
            (payment_date === '' || formatDate(x.payment_date) === formatDate(payment_date))
        );
        setReportList(filtered);
    };


    return (IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "HITEL Payment System Report"]}/>
                <Row className="mt-3">
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <div className="">
                                    <h4 className="">Executive Summary</h4>
                                    <hr/>
                                    <div className="card bg-secondary">
                                        <div className="card-header bg-dark"><div className="card-title  text-muted">Filter Report</div></div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-md-4">
                                                    <label className="form-label">Payment Method</label>
                                                    <select className="form-control" name="payment_method" id="payment_method" value={formData.payment_method} onChange={onEdit}>
                                                        <option value="">All</option>
                                                        {
                                                            paymentMethod.length > 0 && paymentMethod.map((item, index)=>{
                                                                return(
                                                                    <option key={index} value={item}>{item}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="form-label">Payment Type</label>
                                                    <select className="form-control" name="payment_type" id="payment_type" value={formData.payment_type} onChange={onEdit}>
                                                        <option value="">All</option>
                                                        {
                                                            paymentTypes.length > 0 && paymentTypes.map((item, index)=>{
                                                                return(
                                                                    <option key={index} value={item}>{item}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label className="form-label">Payment Date</label>
                                                    <input
                                                        type='date'
                                                        name="payment_date"
                                                        className="form-control"
                                                        id="payment_date"
                                                        value={formData.payment_date}
                                                        onChange={onEdit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <label className="form-label">  Revenue Overview </label>
                                            <Row style={{backgroundColor: '#eeeeee'}}>
                                                <Col md={4} className="mt-2">
                                                    <StatisticWidget
                                                        icon={'las la-dollar-sign'}
                                                        state={currencyConverter(reportList.reduce((acc, service) => acc + service.amount_paid, 0))}
                                                        title={'Total Amount Paid'}
                                                    />
                                                </Col>
                                                <Col md={4}  className="mt-2">
                                                    <StatisticWidget
                                                        icon={'las la-download'}
                                                        state={currencyConverter(reportList.filter(item => item.payment_type === 'Credit').reduce((acc, service) => acc + service.amount_paid, 0))}
                                                        title={'Total Credit'}
                                                    />
                                                </Col>
                                                <Col md={4}  className="mt-2">
                                                    <StatisticWidget
                                                        icon={'las la-upload'}
                                                        state={currencyConverter(reportList.filter(item => item.payment_type === 'Debit').reduce((acc, service) => acc + service.amount_paid, 0))}
                                                        title={'Total Debit'}
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                !showReport ? <></> : <>
                                                    <label className="form-label">  Revenue Trends </label>
                                                    <ComponentContainerCard >
                                                        <div className="chart-demo">
                                                            <ReactApexChart
                                                                height={350}
                                                                options={serviceTypePayment}
                                                                series={serviceTypePayment.series}
                                                                type="line"
                                                                className="apex-charts"
                                                            />
                                                        </div>
                                                    </ComponentContainerCard>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <ComponentContainerCard>
                                                                <div className="chart-demo">
                                                                    <ReactApexChart
                                                                        height={380}
                                                                        options={paymentMethodChart}
                                                                        series={paymentMethodChart.series}
                                                                        type="bar"
                                                                        className="apex-charts"
                                                                    />
                                                                </div>
                                                            </ComponentContainerCard>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};
export default connect(mapStateToProps, null)(PaymentAnalyticsDashboard);