import ComponentContainerCard from '../../common/ComponentContainerCard';
import PageTitle from "../../common/pagetitle/pagetitle";
import {
    Card,
    CardBody,
    Col,
    Row,
} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {
    currencyConverter, formatDate,
    serverLink
} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import ReactApexChart from "react-apexcharts";
import StatisticWidget from "../utils/StatisticWidget";
import ApexChart2 from "react-apexcharts";
import ApexChart3 from "react-apexcharts";
import Chart from "react-google-charts";

const UserActivityReport = ({loginData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [showReport, setShowReport] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [paymentMethodChart, setPaymentMethodChart] = useState({});
    const [apexChartOption, setApexChartOption] = useState({});
    const [apexChartOption2, setApexChartOption2] = useState({});
    const [apexSeries, setApexSeries] = useState([]);
    const [apexSeries2, setApexSeries2] = useState([]);
    const [paymentStatusTrend, setPaymentStatusTrend] = useState([]);
    const [ageChart, setAgeChart] = useState([]);

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        setShowReport(false)
        setIsLoading(true)
        await axios.get(`${serverLink}report/payment/analytics/users`, token).then((result) => {
            if (result.data.message === 'success') {
                console.log(result.data)
                let activity_array, date_array, age_array  = [];
                const ageList = ["18-28", "29-39", "40-50", "51-60"];
                setReportList(result.data.user);
                const uniqueMonths = [...new Set(result.data.user.map(obj => new Date(obj.created_date).toLocaleString('en-US', { year: 'numeric', month: 'short' })))];
                const sortedMonths = uniqueMonths.sort((a, b) => new Date(a) - new Date(b)).slice(0, 12);

                let activity_data = [];
                const reportListData = reportList || [];
                [...new Set(result.data.activities.map(obj => obj.action))].forEach((e) => {
                    let count = result.data.activities.filter(item => item.action.trim().toLowerCase() === e.trim().toLowerCase()).length;
                    activity_data.push({ cat: e, count: count });
                });


                let payment_date_data = [];
                let payment_status_data = [];

                sortedMonths.forEach((e) => {
                    const count = result.data.user.filter(item =>
                        new Date(item.created_date).toLocaleString('en-US', { year: 'numeric', month: 'short' }) === e
                    ).length;

                    payment_date_data.push({ cat: e, count: count });
                });

                ["Male", "Female"].map((e, i) => {
                    let count = result.data.user.filter(item => item.gender === e).length
                    payment_status_data.push([e, count]);
                })
                setPaymentStatusTrend(payment_status_data)

                ageList.map((e) => {
                    let count = result.data.user.filter(item => item.age === e && item.age !== null).length;
                    age_array.push({ cat: e, count: count || 0 });
                    });

                setPaymentStatusTrend(payment_status_data)

                // Set Date Chart Data
                setApexChartOption({
                    ...apexChartOption,
                    chart: {
                        id: "basic-bar"
                    },
                    xaxis: {
                        categories: payment_date_data.map(obj => obj.cat)
                    }
                })
                setApexSeries([{
                    name: "Count",
                    data: payment_date_data.map(obj => obj.count)
                }])

                // Set Age Chart Data
                setApexChartOption2({
                    ...apexChartOption2,
                    chart: {
                        id: "basic-bar"
                    },
                    xaxis: {
                        categories: age_array.map(obj => obj.cat)
                    }
                })
                setApexSeries2([{
                    name: "Count",
                    data: age_array.map(obj => obj.count)
                }])

                const trend_by_user_activity = {
                    chart: {
                        height: 580,
                        type: 'bar',
                        toolbar: {
                            show: false,
                        },
                    },
                    title: {
                        text: 'Activities Trend By Users',
                        align: 'left',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: 'Count',
                            data: activity_data.map(obj => obj.count) ?? [0],
                        },
                    ],
                    colors: ['#2a76f4'],
                    yaxis: {
                        axisBorder: {
                            show: true,
                            color: '#bec7e0',
                        },
                        axisTicks: {
                            show: true,
                            color: '#bec7e0',
                        },
                    },
                    xaxis: {
                        categories: activity_data.map(obj => obj.cat) ?? ['N/A'],
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'none',
                            },
                        },
                    },
                    grid: {
                        borderColor: '#f1f3fa',
                    },
                }

                setPaymentMethodChart(trend_by_user_activity);
                setIsLoading(false);
                setShowReport(true);

            } else {
                toast.error(result.data.message)
            }

        })
            .catch((err) => {
                toast.error('NETWORK ERROR')
            });
    };

    const statusData = [["Gender", "Count"], ...paymentStatusTrend];
    const paymentStatusOptions = {
        title: "User Registration Trend By Gender",
        pieHole: 0.4,
        is3D: true,
    };

    return (IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "User / App Activities Report"]}/>
                <Row className="mt-3">
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <div className="">
                                    <h4 className="">User / App Activities Report</h4>
                                    <hr/>
                                    <Row>
                                        <Col lg={12}>
                                            <label className="form-label"> Overview </label>
                                            <Row style={{backgroundColor: '#eeeeee', marginTop: '10px'}}>
                                                <Col md={3} className="mt-2">
                                                    <StatisticWidget
                                                        icon={'fa fa-users'}
                                                        state={reportList.length}
                                                        title={'Total Users'}
                                                    />
                                                </Col>
                                                <Col md={3} className="mt-2">
                                                    <StatisticWidget
                                                        icon={'las la-user-plus'}
                                                        state={reportList.filter(item => item.status === '1').length}
                                                        title={'Active Users'}
                                                    />
                                                </Col>
                                                <Col md={3} className="mt-2">
                                                    <StatisticWidget
                                                        icon={'las la-user-minus'}
                                                        state={reportList.filter(item => item.status === '0').length}
                                                        title={'InActive Users'}
                                                    />
                                                </Col>
                                                <Col md={3} className="mt-2">
                                                    <StatisticWidget
                                                        icon={'las la-user-minus'}
                                                        state={reportList.filter(item => item.KYC === 1).length}
                                                        title={'Users Completed KYC'}
                                                    />
                                                </Col>
                                            </Row>
                                            <div className="row mt-2 mb-2">
                                                <div className="col-lg-8">
                                                    <div className="card" style={{borderRadius: '10px'}}>
                                                        <div className="col-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h4><b className="text-dark">User Registration Trend
                                                                        By Month and Year</b></h4>
                                                                    {

                                                                        <ApexChart2
                                                                            options={apexChartOption}
                                                                            series={apexSeries}
                                                                            type="bar"
                                                                            height={250}
                                                                            width="100%"
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="card">
                                                        {
                                                            paymentStatusTrend.length > 0 &&
                                                            <Chart
                                                                chartType="PieChart"
                                                                width="100%"
                                                                height="310px"
                                                                data={statusData}
                                                                options={paymentStatusOptions}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {<>
                                                    {/*<label className="form-label"> Revenue Trends </label>*/}
                                                    <Row>
                                                        <Col lg={12}>
                                                            <ComponentContainerCard>
                                                                <div className="chart-demo">
                                                                    <ReactApexChart
                                                                        height={580}
                                                                        options={paymentMethodChart}
                                                                        series={paymentMethodChart.series}
                                                                        type="bar"
                                                                        className="apex-charts"
                                                                    />
                                                                </div>
                                                            </ComponentContainerCard>
                                                        </Col>
                                                    </Row>
                                                <ComponentContainerCard>
                                                    <div className="chart-demo">
                                                        <h4><b className="text-dark">User Registration Trend
                                                            By Age</b></h4>
                                                        {

                                                            <ApexChart3
                                                                options={apexChartOption2}
                                                                series={apexSeries2}
                                                                type="bar"
                                                                height={250}
                                                                width="100%"
                                                            />
                                                        }
                                                    </div>
                                                </ComponentContainerCard>
                                                </>
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};
export default connect(mapStateToProps, null)(UserActivityReport);