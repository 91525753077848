import React from "react";

export default function ManageSystemAdminForm(props) {
    return (
        <>
            <div
                id={`${
                    typeof props.id !== "undefined" ? props.id : "responsive-modal"
                }`}
                className="modal fade bs-example-modal-lg"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
                style={{ display: "none" }}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">
                                {props.title ?? ""}
                            </h4>
                            <button
                                type="button"
                                className={`${
                                    typeof props.close !== "undefined" ? props.close : "close"
                                }`}
                                id={`${
                                    typeof props.close !== "undefined" ? props.close : "close"
                                }`}
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 mb-3" style={{backgroundColor: '#eeeeee'}}>
                                    <h4 className="card-title font-weight-bold pt-2">Personal Info</h4>
                                </div>
                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">First Name</label>
                                    <input
                                        name="first_name"
                                        className="form-control"
                                        id="first_name"
                                        value={props.formData.first_name}
                                        onChange={props.onEdit}
                                        placeholder="First Name"
                                    />
                                </div>

                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Middle Name</label>
                                    <input
                                        name="middle_name"
                                        className="form-control"
                                        id="middle_name"
                                        value={props.formData.middle_name}
                                        onChange={props.onEdit}
                                        placeholder="Middle Name"
                                    />
                                </div>

                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Surname</label>
                                    <input
                                        name="surname"
                                        className="form-control"
                                        id="surname"
                                        value={props.formData.surname}
                                        onChange={props.onEdit}
                                        placeholder="Surname"
                                    />
                                </div>
                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Gender</label>
                                    <select className="form-control" name="gender" id="gender"
                                            value={props.formData.gender} onChange={props.onEdit}>
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Email Address</label>
                                    <input
                                        name="email_address"
                                        className="form-control"
                                        id="email_address"
                                        value={props.formData.email_address}
                                        onChange={props.onEdit}
                                        placeholder="Email Address"
                                    />
                                </div>

                                <div className="col-md-12 mb-3" style={{backgroundColor: '#eeeeee'}}>
                                    <h4 className="card-title font-weight-bold pt-2">Other Details</h4>
                                </div>

                                <div className="mb-3 form-group col-md-12">
                                    <label className="form-label">Status</label>
                                    <select className="form-control" name="status" id="status"
                                            value={props.formData.status} onChange={props.onEdit}>
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default waves-effect"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            {props.IsFormLoading ? (
                                <button type="button" className="btn btn-primary ms-auto">
                  <span>
                    <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                    />{" "}
                      Please wait...
                  </span>
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={props.onSubmit}
                                    className="btn btn-danger waves-effect waves-light"
                                >
                                    {props.btn ?? "submit"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
