import React from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../common/header/header";
import SideBar from "../common/sidebar/sidebar";
import DashBoard from "../dashboard/dashboard";
import Footer from "../common/footer/footer";
import ChangePassword from "../change-password/change-password";
import ManageUsers from "../user-management/user-management";
import MenuSettings from "../settings/menu/menu-settings";
import PermissionSettings from "../settings/permission/permission";
import Group from "../settings/group/group";
import UserDashboard from "../user-management/user-dashboard";
import PaymentReportDashboard from "../report/payment/dashboard";
import MonthlyPaymentReport from "../report/payment/monthly-income";
import DailyPaymentReport from "../report/payment/daily-income";
import ReceiptsVoucherReport from "../report/payment/receipts-voucher";
import PaymentAnalyticsDashboard from "../report/payment/payment-analytics-dashboard";
import DataPurchase from "../report/utility-bills/data-purchase";
import AirtimePurchase from "../report/utility-bills/airtime-purchase";
import ElectricityBillPayment from "../report/utility-bills/electricity-bill-payment";
import CableTVSubscription from "../report/utility-bills/cable-tv-subscription";
import FundUserWallet from "../user-management/wallet-topup/wallet-topup";
import PriceControl from "../app-management/price-control/price-control";
import SystemAdmin from "../system-admin/manage-system-admin";
import AnalyticsReportDashboard from "../report/analytics/dashboard";
import PaymentAnalyticsReport from "../report/analytics/payment-report";
import UserActivityReport from "../report/analytics/user-activities-report";
import DataPurchaseDaily from "../report/utility-bills/data-purchase-daily";

const PageRoutes = (props) => {
  const [toggle, setToggle] = useState("");

  const toggleSidebar = () => {
    const tg = toggle === "" ? "toggle-sidebar" : "";
    setToggle(tg);
  };

  return (
      <div className={toggle}>
        <Header toggleSidebar={toggleSidebar} />
        <SideBar />
        <div className="page-wrapper">
          <Routes>
            <Route path={"/"} element={<DashBoard />} />
            <Route path={"/dashboard"} element={<DashBoard />} />

            {/*/!* SETTINGS *!/*/}
            <Route path="/settings/permission/group" element={<Group />} />
            <Route path="/settings/permission/menu-settings" element={<MenuSettings />}/>
            <Route path="/settings/permission/permission-settings" element={<PermissionSettings />}/>

            {/*/!* USER *!/*/}
            <Route path="/user/user-manager" element={<ManageUsers />} />
            <Route path="/user/user-dashboard/:slug" element={<UserDashboard />} />
            <Route path="/user/fund-user-wallet" element={<FundUserWallet />} />
            <Route path="/admin/users" element={<SystemAdmin />} />
            <Route path="/change-password" element={<ChangePassword />} />
            {/*<Route path="/website/gallery" element={<div className="text-center alert alert-info"><h1>Page Under Construction</h1></div>} />*/}

            {/*/!* REPORT *!/*/}
            <Route path="/report/payment" element={<PaymentReportDashboard />} />
            <Route path="/report/monthly-income" element={<MonthlyPaymentReport />} />
            <Route path="/report/daily-income" element={<DailyPaymentReport />} />
            <Route path="/report/receipts-voucher" element={<ReceiptsVoucherReport />} />
            <Route path="/report/payment/payment-executive-summary" element={<PaymentAnalyticsDashboard />} />
            <Route path="/report/data-purchase" element={<DataPurchase />} />
            <Route path="/report/data-purchase-daily" element={<DataPurchaseDaily />} />
            <Route path="/report/airtime-purchase" element={<AirtimePurchase />} />
            <Route path="/report/electricity-bill-payment" element={<ElectricityBillPayment />} />
            <Route path="/report/cable-tv-subscription" element={<CableTVSubscription />} />
            <Route path="/report/analytics" element={<AnalyticsReportDashboard />} />
            <Route path="/report/analytics/payment" element={<PaymentAnalyticsReport />} />
            <Route path="/report/analytics/user-activities" element={<UserActivityReport />} />

            {/*/!* APP *!/*/}
            <Route path="/app/price-control" element={<PriceControl />} />

            <Route path="*" element={<DashBoard />} />
          </Routes>
          <Footer />
        </div>
      </div>
  );
};
export default PageRoutes;